import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import PreviewRow from '../components/blog/PreviewRow'
import Header from '../components/common/Header'
import Layout from '../components/layout'
import Seo from '../components/seo'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    // sort blog posts with oldest last
    const orderedPosts = posts.sort((a, b) => {
      return new Date(b.rawDate) - new Date(a.rawDate)
    })

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Blog | Urban Fox | EV Charging Network"
          description="Insights, opinion and product updates from the Urban Fox team."
        />
        <Header filter="News" />
        <PreviewRow posts={orderedPosts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        author {
          name
          heroImage: image {
            gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1180)
          }
        }
        rawDate: publishDate
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          raw
        }
        description {
          raw
        }
      }
    }
  }
`
